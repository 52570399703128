import React from 'react'
import {Link} from 'react-router-dom'
import CSS from './ConstructionStyling.component.css'

function ConstructionPage(){
    return (
        <>
        <div className="construction-page">
            <div className="construction-spacer"></div>

            <div className="construction-heading">
                <h1>Welcome to His Image Studio Construction and Inspecting Services</h1>
                <span>His Image Studio can provide a variety of videos related to inspection and construction services.<br/>Many companies even use a weekly "flyover" to document building progress that is supplied to owners or managers.<br/>Documentation of equipment and materials on site is also prudent for insurance and security reasons.<br/>Please feel free to use the "Contact Us" section with any questions about these services.</span>
                <span><br/><br/></span>
                <span className="construction-disclaimer"><em>The following videos do not contain audio as the other videos on this site contain.</em></span>
            
            </div>

            <h2>Inspections of power line and towers from top to bottom<br/>can assess damage from weather or long term aging.</h2>
            <iframe src="https://www.youtube.com/embed/QgnK3zAIcKA?rel=0"></iframe>

            <h3>Cell phone towers and equipment can be quickly evaluated<br/>using drones to determine many issues prior to the costly prospect<br/>of sending cranes or multi-person crews to make repairs or modifications.</h3>
            <iframe src="https://www.youtube.com/embed/Uum4eBZH2ok?rel=0"></iframe>

            <h2>Government municipalities use His Image Studio to inspect<br/>all sorts of property issues - including water towers.</h2>
            <iframe src="https://www.youtube.com/embed/x8O6ta3aYZU?rel=0"></iframe>

            <h3>Construction companies use His Image Studio to record a vast array of<br/>building projects, from civil engineering aspects to final construction phases.</h3>
            <iframe src="https://www.youtube.com/embed/-exJW1jSip4?rel=0"></iframe>

        </div>
        </>
    )
}

export default ConstructionPage